<template lang="pug">
#verify-box.step-component(:class="{ 'has-error': errors }")
  h2 First thing first, let's verify your kit box ID
  p ID is on the sticker inside the box flap  
  input.input(
    :class="{ 'has-error': $v.boxId.$error }",
    type="text",
    v-mask="'NNN NNN NNN NNN'",
    v-model="boxId",
    placeholder="XXX XXX XXX XXX"
  )

  p.is-error(v-if="errors") There was an error, please try again

  ContactSupportBlock(
    v-if="hasError",
    titleIntro="Having trouble validating your kit?",
    emailSubject="Box validation help required",
    :emailBody="validationFailedEmailBody"
  )

  VerificationButton.button(
    @click.native="submit()",
    text="Confirm",
    :state="verificationButtonState"
  )
</template>

<script>
import { minLength, maxLength, required } from 'vuelidate/lib/validators'

export default {
  props: {},

  data() {
    return {
      boxId: '',
      hasError: false,
      verificationButtonState: 'init',
    }
  },

  validations: {
    boxId: {
      required,
      minLength: minLength(15),
      maxLength: maxLength(15),
    },
  },

  computed: {
    errors,
    validationFailedEmailBody,
  },

  watch: {},

  methods: {
    submit,
  },

  components: {
    ContactSupportBlock: require('@/components/ContactSupportBlock').default,
    VerificationButton: require('@/components/VerificationButton').default,
  },
}

/* Computed ---------------------------------------------------- */
function errors() {
  return this.$v.$anyError || this.hasError
}

function validationFailedEmailBody() {
  return `Attempting to validate kit '${this.boxId}'`
}

/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
async function submit() {
  this.verificationButtonState = 'try'

  this.$v.$touch()

  if (this.$v.$invalid) {
    this.verificationButtonState = 'init'
  } else {
    this.verificationButtonState = 'try'

    try {
      const response = await this.$store.dispatch('box/verifyFetch', {
        barcode: this.boxId?.replace(/[^a-zA-Z\d]+/g, ''),
      })

      if (response.status == 200) {
        this.$bugsnag.setUser(response.data.barcodeId, null, null)
        this.$bugsnag.addMetadata('kitType', response.data.kitType)

        this.verificationButtonState = 'success'
        this.hasError = false
        this.$store.commit('user/SET_MEDPLUM_ORDER_ID', response.data.id)
        this.$store.commit('user/SET_BARCODE_ID', response.data.barcodeId)
        this.$store.commit('user/SET_KIT_TYPE', response.data.kitType)
        this.$store.commit('user/SET_FLOW_SECTIONS', response.data.flowSections)
        this.$store.commit('user/SET_CONFIG_KEYS', response.data.configKeys)
        this.$store.commit('user/SET_SKU', response.data.sku)
        localStorage.setItem('displayWarning', response.data.displayWarning)
        this.$emit('append')

        this.$amplitude.getInstance().setUserId(response.data.barcodeId)
        this.$mixpanel.identify(response.data.barcodeId)

        setTimeout(() => {
          this.$emit('complete')
        }, 1000)
      } else {
        this.verificationButtonState = 'init'
        this.hasError = true
      }
    } catch (error) {
      this.verificationButtonState = 'init'
      this.hasError = true
    }
  }
}
</script>
